<template>
  <StudentClassSessions />
</template>

<script>
import StudentClassSessions from "@/packages/Reports/components/StudentClassSessions";
export default {
  name: "upcoming",
  components: { StudentClassSessions },
};
</script>

<style scoped></style>
