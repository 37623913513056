<template>
  <v-container fluid>
    <v-sheet :height="100">
      <v-toolbar flat>
        <v-card-title>{{ $_t("attribute.upcoming_classes") }}</v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn outlined class="mr-4" @click="setToday"> Today </v-btn>
        <v-btn color="primary" class="px-0 mr-3" @click="prev">
          <v-icon large> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn color="primary" class="px-0 mr-4" @click="next">
          <v-icon large> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
      </v-toolbar>
      <div class="d-flex justify-start flex-fill flex-wrap px-4">
        <span class="d-flex align-center flex-row mr-9">
          <v-sheet
            width="15px"
            height="15px"
            :color="event_colors.waiting_for_class"
            class="rounded-pill mr-2"
          ></v-sheet>
          {{ $_t("attribute.normalClass") }}
        </span>
        <span class="d-flex align-center flex-row mr-9">
          <v-sheet
            width="15px"
            height="15px"
            :color="event_colors.reserved"
            class="rounded-pill mr-2"
          ></v-sheet>
          {{ $_t("attribute.reservedClass") }}
        </span>
      </div>
    </v-sheet>
    <v-sheet v-if="events">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :locale="$i18n.locale"
        color="primary"
        :type="'week'"
        :events="events"
        :event-color="getEventColor"
      >
        <template v-slot:event="{ event, eventSummary }">
          <div @click="eventDetails(event)">
            <div>
              {{ `${$_t("attribute.room")}: ${event.classroomNumber}` }}
            </div>
            <div>
              {{ truncateString(event.classroomName, 28) }}
            </div>
            <div v-if="event">
              {{ `${$_t("attribute.teacher")}: ${event.teacherName}` }}
            </div>
            <div class="v-event-draggable">
              <component :is="{ render: eventSummary }"></component>
            </div>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";

export default {
  name: "StudentClassSessions",
  data() {
    return {
      events: [],
      focus: "",
      event_colors,
    };
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
  methods: {
    async initSessions() {
      const { events } = await this.$actions.getClassroomsOfUser({
        publicKey: this.user.publicKey,
      });
      this.events = events;
    },
    eventDetails(event) {
      console.log(event);
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },
  },
  mounted() {
    this.initSessions();
  },
};
</script>

<style scoped></style>
